import color from "color";
import { EmployerModeContext, SetEmployerModeContext } from "contexts/employer-mode-context";
import dropProps from "hoc/drop-props";
import { ContentContext } from "hooks/use-content";
import React, { useContext } from "react";
import styled, { css } from "styled-components";
import themeColor from "styles/theme-color";
import themeTransition from "styles/theme-transition";
import { childSpacing, margin, padding } from "styles/utils";

const Root = styled.div`
    background-color: rgba(255, 255, 255, 0.25);
    font-size: 1rem;
    line-height: 1.875rem;
    border-radius: 1ch;
    padding: 0.5ch;
    ${margin.centerX};
    ${childSpacing({ horizontal: "0.5ch" })};
    display: flex;
    > * {
        flex: 1 1 0;
    }
`;

const StyledButton = styled(dropProps("active")("button"))<{ active: boolean }>`
    border-radius: 0.75ch;
    border: none;
    box-shadow: none;
    font-size: inherit;
    transition: all ${themeTransition("fast")};
    cursor: pointer;
    font-weight: normal;
    ${padding.y("5px")};
    ${p =>
        p.active &&
        css`
            background-color: white;
            color: ${themeColor("fontDark")};
        `}
    ${p =>
        !p.active &&
        css`
            background-color: transparent;
            color: ${themeColor("fontDark")};
            &:hover {
                background-color: #fff4;
            }
            &:active {
                background-color: #0002;
            }
        `}
`;

const EmployerButton = styled(StyledButton)`
    ${p =>
        p.active &&
        css`
            background-color: ${p.theme.palette.blue};
            color: white;
        `}
    ${p =>
        !p.active &&
        css`
            :hover {
                background-color: ${color(p.theme.palette.blue)
                    .alpha(0.2)
                    .toString()};
            }
        `}
`;
const EmployeeButton = styled(StyledButton)`
    ${p =>
        p.active &&
        css`
            background-color: ${p.theme.palette.green};
            color: white;
        `}
    ${p =>
        !p.active &&
        css`
            :hover {
                background-color: ${color(p.theme.palette.green)
                    .alpha(0.2)
                    .toString()};
            }
        `}
`;

export interface EmployerModeSwitchProps extends PropsOf<typeof Root> {
    employerProps?: Partial<PropsOf<typeof StyledButton>>;
    employeeProps?: Partial<PropsOf<typeof StyledButton>>;
    classes?: {
        activeButton?: ReturnType<typeof css>;
        inactiveButton?: ReturnType<typeof css>;
    };
}
export default function EmployerModeSwitch({
    employeeProps,
    employerProps,
    classes,
    ...rootProps
}: EmployerModeSwitchProps) {
    const employerModeOn = useContext(EmployerModeContext);
    const setEmployerMode = useContext(SetEmployerModeContext);
    const content = useContext(ContentContext).home.section09;

    return (
        <Root {...rootProps}>
            <EmployerButton
                data-active={employerModeOn.toString()}
                {...employerProps}
                active={employerModeOn}
                onClick={() => setEmployerMode(true)}
            >
                {content.left.headerText}
            </EmployerButton>
            <EmployeeButton
                data-active={!employerModeOn.toString()}
                {...employeeProps}
                active={!employerModeOn}
                onClick={() => setEmployerMode(false)}
            >
                {content.right.headerText}
            </EmployeeButton>
        </Root>
    );
}
