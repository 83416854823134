import DynamicImage from "components/dynamic-image";
import Slider from "components/slider";
import { ContentContext } from "hooks/use-content";
import useMediaSSR from "hooks/use-media-ssr";
import ArrowRight from "images/home/section01/arrow-right.inline.svg";
import React, { useContext } from "react";
import SSRMQValues from "services/ssr-mq-values";
import styled from "styled-components";
import { desktop } from "styles/media-queries";
import themeColor from "styles/theme-color";
import themeTransition from "styles/theme-transition";
import { childSpacing, contentCss, margin, padding, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section`
    ${rootCss};
    padding-top: 3rem;
    background-image: linear-gradient(to bottom, transparent, white);
    padding-bottom: 80px;
`;

const TextContent = styled.div`
    ${contentCss};
    margin-bottom: 4.5rem;
`;

const LogosContent = styled.div`
    @media ${desktop.andUp().toString()} {
        ${contentCss};
    }
`;

const Text = styled.h2`
    ${zeplinLerp("font-size", 32, 40)};
    margin-bottom: 2.5rem;

    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;

const StyledSlider = styled(Slider)`
    && {
        @media ${desktop.andUp().toString()} {
            display: flex;
            justify-content: space-between;
        }
    }
`;

const Logo = styled(DynamicImage)`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;
const LogoWrapper = styled.div`
    height: 60px;
    ${padding.x("1rem")};
`;

const SliderDotsContainer = styled.div`
    ${margin.y("2rem")};
    display: flex;
    justify-content: center;
    ${childSpacing({ horizontal: "0.5rem" })};
    list-style: none;
`;

const SliderDot = styled.div`
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${themeColor("fontGrey")};
    transition: all ${themeTransition("fast")};
    li.slick-active > & {
        background-color: ${themeColor("blue")};
    }
`;

const StyledArrowRight = styled(ArrowRight)`
    &,
    .bg,
    .arrow {
        transition: all ${themeTransition()};
    }
    .bg {
        fill: ${themeColor("blue")};
    }
    .arrow {
        stroke: white;
    }
    :hover {
        .bg {
            fill: ${themeColor("fontSemiLight")};
        }
        .arrow {
            stroke: black;
        }
        filter: brightness(1.2);
    }
    :active {
        filter: brightness(0.8);
    }
    cursor: pointer;
    flex: 0 0 auto;
    ${margin.x("2rem")};
` as typeof ArrowRight;

const StyledArrowLeft = styled(StyledArrowRight)`
    transform: scaleX(-1);
` as typeof StyledArrowRight;

function Section01() {
    const content = useContext(ContentContext).home.section01;
    const isMobile = useMediaSSR(desktop.smallerThanThis().toString(), SSRMQValues);

    return (
        <Root>
            <TextContent>
                <Text>{content.text}</Text>
            </TextContent>
            <LogosContent>
                <StyledSlider
                    infinite
                    arrows={!isMobile}
                    dots={isMobile}
                    touchThreshold={100}
                    slidesToShow={isMobile ? 3 : 4}
                    slidesToScroll={isMobile ? 3 : 4}
                    customPaging={idx => <SliderDot />}
                    appendDots={dots => <SliderDotsContainer>{dots}</SliderDotsContainer>}
                    nextArrow={<StyledArrowRight />}
                    prevArrow={<StyledArrowLeft />}
                >
                    {content.logos.map((logo, i) => (
                        <LogoWrapper key={i}>
                            <Logo {...(typeof logo === "string" ? { src: logo } : logo)} />
                        </LogoWrapper>
                    ))}
                </StyledSlider>
            </LogosContent>
        </Root>
    );
}
export default Section01;
