import ButtonLink from "components/button-link";
import Markdown from "components/markdown";
import { ContentContext } from "hooks/use-content";
import React, { useContext } from "react";
import styled from "styled-components";
import { desktop } from "styles/media-queries";
import shadowedButton from "styles/shadowed-button";
import themeColor from "styles/theme-color";
import { contentCss, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section`
    ${rootCss}
    ${zeplinLerp("padding-top", 16, 72)};
    ${zeplinLerp("padding-bottom", 16, 72)};
`;

const Content = styled.div`
    ${contentCss}
    --preferred-content-width: 900px;

    display: flex;
    @media ${desktop.andUp().toString()} {
        justify-content: space-between;
        align-items: center;
    }
    @media ${desktop.smallerThanThis().toString()} {
        flex-direction: column;
        align-items: center;
    }
`;

const TextContainer = styled.div`
    @media ${desktop.smallerThanThis().toString()} {
        margin-bottom: 1.5rem;
        text-align: center;
    }
`;

const Text1 = styled(Markdown)`
    font-style: italic;
    ${zeplinLerp("font-size", 16, 24)};
    line-height: 2rem;

    & > :last-child {
        margin-block-end: 0;
    }

    @media ${desktop.andUp().toString()} {
        margin-bottom: 1.25rem;
    }
`;
const Text2 = styled(Markdown)`
    ${zeplinLerp("font-size", 24, 40)}
    line-height: 3.5rem;
    font-weight: bold;

    strong {
        color: ${themeColor("blue")};
        font-weight: inherit;
    }

    & > :last-child {
        margin-block-end: 0;
    }
`;

const Button = styled(ButtonLink)`
    ${p => shadowedButton({ color: p.theme.palette.orange })};
    color: white;
    padding: 1.5rem 2.75rem;
    font-weight: bold;
`;

function Section06() {
    const content = useContext(ContentContext).home.section06;
    return (
        <Root>
            {/* <Content>
                <TextContainer>
                    <Text1>{content.text1}</Text1>
                    <Text2>{content.text2}</Text2>
                </TextContainer>
                <Button to={content.button.link}>{content.button.text}</Button>
            </Content> */}
        </Root>
    );
}
export default Section06;
