import color from "color";
import DynamicImage from "components/dynamic-image";
import EmployerModeSwitch from "components/employer-mode-switch";
import { EmployerModeContext, EmployerModeProvider } from "contexts/employer-mode-context";
import { DynamicImageContent } from "declarations/content";
import dropProps from "hoc/drop-props";
import { ContentContext } from "hooks/use-content";
import useIsClient from "hooks/use-is-client";
import useMediaSSR from "hooks/use-media-ssr";
import useTheme from "hooks/use-theme";
import quote from "images/home/section09/quote.svg";
import React, { useContext } from "react";
import SSRMQValues from "services/ssr-mq-values";
import styled, { css } from "styled-components";
import { desktop } from "styles/media-queries";
import themeColor from "styles/theme-color";
import { contentCss, margin, padding, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section(
    p => css`
        ${rootCss};
        background-color: white;
        color: ${themeColor("fontDark")};
        /* background-image: linear-gradient(to bottom, ${p.theme.palette.backgroundLight}, white); */
        ${zeplinLerp("padding-top", 80, 120)};
        ${zeplinLerp("padding-bottom", 48, 120)};
    `,
);

const Content = styled.div(
    p => css`
        ${contentCss};
        @media ${p.theme.mq.anyMobile.toString()} {
            text-align: center;
        }
    `,
);

const Quote = styled.blockquote(
    p => css`
        background-image: url(${quote});
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 46px 38px;
        padding-top: calc(38px + 24px);
        color: ${themeColor("fontDark")};
        font-size: calc(20 / 16 * 1rem);
        font-weight: bold;
        margin-inline-start: 0;
        margin-inline-end: 0;
        margin-bottom: 64px;
        @media ${p.theme.mq.anyMobile.toString()} {
            background-position-x: center;
        }
    `,
);

const CaseStudies = styled(dropProps("mode")("div"))<{ mode?: "employer" | "employee" }>(
    p => css`
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr;
        grid-template-rows:
            max-content
            max-content
            max-content;
        column-gap: 2rem;
        @media (max-width: 1279px) {
            grid-template-columns: 1fr;
            grid-auto-flow: row;

            ${p.mode === "employee" &&
                css`
                    > :first-child {
                        display: none;
                    }
                `};
            ${p.mode === "employer" &&
                css`
                    > :last-child {
                        display: none;
                    }
                `};
        }
    `,
);

const CaseStudy = (() => {
    const Root = styled.div(
        p => css`
            display: contents;
            :nth-child(2n + 1) {
                --color: ${p.theme.palette.blue};
                --bgcolor: #e0e8ff;
            }
            :nth-child(2n) {
                --color: ${p.theme.palette.green};
                --bgcolor: #ebfaed;
            }
            /* @media (max-width: 1279px) {
                display: block;
            } */
        `,
    );
    const Header = styled.div``;
    const HeaderMark = styled.div(
        p => css`
            display: inline-block;
            border-radius: 0.5rem;
            background-color: var(--bgcolor);
            color: var(--color);
            text-align: center;
            width: calc(122 / 16 * 1rem);
            ${padding.y("5px")};
            font-size: 1rem;
            line-height: calc(30 / 16);
        `,
    );
    const Quote = styled.blockquote(
        p => css`
            font-style: italic;
            font-size: calc(24 / 16 * 1rem);
            line-height: 1.67;
            color: ${themeColor("fontDark")};
            margin-inline-start: 0;
            margin-inline-end: 0;
            ${zeplinLerp("font-size", 20, 24)};
            ${zeplinLerp("line-height", 32, 40)};
        `,
    );

    const Person = styled.div(
        p => css`
            display: grid;
            grid-template-areas:
                "name logo"
                "job  logo";
            grid-template-columns: max-content auto;
            column-gap: 2rem;
            ${margin.y("4rem")};
            @media ${p.theme.mq.anyMobile.toString()} {
                grid-template-areas:
                    "logo"
                    "."
                    "name"
                    "job";
                grid-template-columns: 1fr;
                grid-template-rows: 2rem 24px auto auto;
            }
        `,
    );
    const PersonName = styled.span(
        p => css`
            grid-area: name;
            align-self: flex-end;
            color: ${themeColor("fontDark")};
            font-weight: bold;
            font-size: 1.125rem;
            line-height: 1.75rem;
        `,
    );
    const PersonJob = styled.span(
        p => css`
            grid-area: job;
            color: ${themeColor("fontSemiDark")};
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.875rem;
            line-height: 1.75rem;
            letter-spacing: 1px;
        `,
    );
    const PersonLogoContainer = styled.div`
        grid-area: logo;
        display: flex;
        justify-content: center;
    `;
    const PersonLogo = styled(DynamicImage)`
        height: 100%;
        width: 100%;
        object-position: left center;
        object-fit: contain;

        @media (max-width: 1279px) {
            width: auto;
        }
    `;

    interface CaseStudyProps {
        who: string;
        quote: string;
        name: string;
        job: string;
        logo: string | DynamicImageContent;
    }
    return function CaseStudy(props: CaseStudyProps) {
        const isDesktop = useMediaSSR(desktop.andUp().toString(), SSRMQValues);
        return (
            <Root>
                <Header>{isDesktop && <HeaderMark>{props.who}</HeaderMark>}</Header>

                <Quote>{props.quote}</Quote>

                <Person>
                    <PersonName>{props.name}</PersonName>
                    <PersonJob>{props.job}</PersonJob>
                    <PersonLogoContainer>
                        <PersonLogo {...(typeof props.logo === "string" ? { src: props.logo } : props.logo)} />
                    </PersonLogoContainer>
                </Person>
            </Root>
        );
    };
})();

const StyledEmployerModeSwitch = styled(EmployerModeSwitch)(
    p => css`
        background-color: rgba(163, 173, 186, 0.2);
        ${margin.y("2rem")};

        @media ${p.theme.mq.anyDesktop.toString()} {
            display: none;
        }
    `,
);

const employerModeSwitchActiveButtonCss = css`
    :first-child {
        --bgcolor: ${p => p.theme.palette.blue};
        --hoverbgcolor: ${p =>
            color(p.theme.palette.blue)
                .darken(0.2)
                .hex()};
    }
    :last-child {
        --bgcolor: ${themeColor("green")};
        --hoverbgcolor: ${p =>
            color(p.theme.palette.green)
                .darken(0.2)
                .hex()};
    }
    background-color: var(--bgcolor);
    :hover {
        background-color: var(--hoverbgcolor);
    }
    color: white;
`;
const employerModeSwitchInactiveButtonCss = css`
    color: ${themeColor("fontSemiDark")};
`;

function Section09Inner() {
    useIsClient();
    const content = useContext(ContentContext).home.section09;
    const theme = useTheme();
    const isMobile = useMediaSSR(desktop.smallerThanThis().toString(), SSRMQValues);
    const employerModeOn = useContext(EmployerModeContext);

    return (
        <Root>
            <Content>
                <Quote>{content.quote}</Quote>
                <CaseStudies>
                    <CaseStudy
                        who={content.left.headerText}
                        quote={content.left.quote}
                        name={content.left.authorPrimary}
                        job={content.left.authorSecondary}
                        logo={content.left.logo}
                    />
                    <CaseStudy
                        who={content.right.headerText}
                        quote={content.right.quote}
                        name={content.right.authorPrimary}
                        job={content.right.authorSecondary}
                        logo={content.right.logo}
                    />
                </CaseStudies>
            </Content>
        </Root>
    );
}

function Section09() {
    return (
        <EmployerModeProvider>
            <Section09Inner />
        </EmployerModeProvider>
    );
}
export default Section09;
