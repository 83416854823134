import ButtonLink from "components/button-link";
import { ContentContext } from "hooks/use-content";
import backgroundDesktop from "images/home/section00/background-desktop.svg";
import backgroundMobile from "images/home/section00/background-mobile.svg";
import React, { useContext } from "react";
import styled from "styled-components";
import { desktop } from "styles/media-queries";
import shadowedButton from "styles/shadowed-button";
import { contentCss, rootCss, margin } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";
import { Badge } from "components/badge";

const Root = styled.section`
    margin-top: var(--header-height);

    background-repeat: no-repeat;

    @media ${desktop.andUp().toString()} {
        background-image: url(${backgroundDesktop});
        background-size: cover;
        background-position: center bottom;
    }

    @media ${desktop.smallerThanThis().toString()} {
        background-image: url(${backgroundMobile});
        background-size: cover;
        background-position: center bottom;
    }

    @media (max-width: 370px) {
        min-height: 130vh;
    }

    min-height: 100vh;
    ${rootCss};
`;

const Content = styled.div`
    ${contentCss};
    ${zeplinLerp("padding-top", 40, 146)};
`;

const Text = styled.h1`
    font-weight: bold;
    color: white;
    ${zeplinLerp("font-size", 47, 72)};
    line-height: 1;
    ${zeplinLerp("margin-bottom", 24, 40)};
`;
const Button = styled(ButtonLink)`
    font-size: 1rem;
    line-height: 1;
    ${shadowedButton({ color: "white" })}
    padding: 1.25rem 3.125rem;
`;

const ContentConstraint = styled.div`
    max-width: 650px;
    margin-right: auto;
    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
    @media ${desktop.andUp().toString()} {
        text-align: left;
    }
`;

const Badges = styled.div`
    position: absolute;
    bottom: 100px;
    display: flex;
    justify-content: center;
    width: var(--preferred-content-width);
    padding-right: 17.2%;
    left: 0;
    right: 0;
    ${margin.centerX};
    @media ${desktop.smallerThanThis().toString()} {
        width: 100%;
        position: relative;
        bottom: 0;
        margin-top: 20px;
        padding-right: 0;
    }
`;

const StyledBadge = styled(Badge)`
    &:last-of-type {
        margin-left: 30px;
    }
`;

function Section00() {
    const content = useContext(ContentContext);
    const sectionContent = content.home.section00;
    const commonContent = content.common;

    return (
        <div style={{ position: "relative" }}>
            <Root>
                <Content>
                    <ContentConstraint>
                        <Text>{sectionContent.text}</Text>
                        <Button to={sectionContent.button.link}>{sectionContent.button.text}</Button>
                    </ContentConstraint>
                </Content>
            </Root>
            <Badges>
                <StyledBadge link={commonContent.appStore.link} image={commonContent.appStore.logo} />
                <StyledBadge link={commonContent.googlePlay.link} image={commonContent.googlePlay.logo} />
            </Badges>
        </div>
    );
}
export default Section00;
