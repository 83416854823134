import Color from "color";
import ButtonLink from "components/button-link";
import DynamicImage from "components/dynamic-image";
import Markdown from "components/markdown";
import Slider from "components/slider";
import { DynamicImageContent } from "declarations/content";
import dropProps from "hoc/drop-props";
import { ContentContext } from "hooks/use-content";
import useMediaSSR from "hooks/use-media-ssr";
import headerIcon from "images/home/section04/header-icon.svg";
import React, { Children, isValidElement, ReactNode, useContext } from "react";
import SSRMQValues from "services/ssr-mq-values";
import styled, { css } from "styled-components";
import { desktop } from "styles/media-queries";
import shadowedButton from "styles/shadowed-button";
import themeColor from "styles/theme-color";
import themeTransition from "styles/theme-transition";
import { childSpacing, contentCss, margin, padding, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section`
    position: relative;

    ${zeplinLerp("padding-top", 104, 110)};
    ${zeplinLerp("padding-bottom", 96, 126)};

    ::before {
        content: "";
        position: absolute;
        background-image: url(${headerIcon});
        background-size: 100% 100%;
        background-repeat: no-repeat;
        left: 50%;
        top: 0;
        transform: translate3d(-50%, -50%, 0);
        width: 10rem;
        height: 10rem;
    }
`;
const HeadingSectionFragment = styled.div`
    ${rootCss}
`;
const HeadingContent = styled.div`
    ${contentCss}
    text-align: center;
    --preferred-content-width: 900px;
    ${zeplinLerp("margin-bottom", 45, 56)};
`;

const Heading = styled.h2`
    ${zeplinLerp("font-size", 32, 40)};
    line-height: 3rem;
`;
const Text = styled(Markdown)`
    color: ${themeColor("fontSemiDark")};
    ${zeplinLerp("font-size", 16, 18)};
    ${zeplinLerp("line-height", 28, 40)};
`;
interface CardsProps {
    children?: ReactNode;
}
const Cards = (() => {
    const Root = styled.div`
        ${zeplinLerp("margin-bottom", 64, 100)};
    `;

    function yOffset(i: number) {
        switch (i % 4) {
            case 0:
                return 0;
            case 1:
                return -40;
            case 2:
                return 40;
            case 3:
                return -56;
        }
    }

    const DesktopCards = styled.div`
        display: flex;
        justify-content: center;
        ${childSpacing({ horizontal: "1.5rem" })};
        ${padding.y("80px")};
    `;

    const DesktopCardWrapper = styled(dropProps("index")("div"))<{ index: number }>`
        transform: translateY(${p => yOffset(p.index)}px);
    `;

    const MobileDots = styled.div`
        display: flex;
        justify-content: center;
        ${childSpacing({ horizontal: "0.5rem" })};
        ${margin.y("1.5rem")};

        & > li {
            list-style: none;
        }
    `;
    const MobileDot = styled.div`
        --size: 0.5rem;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;

        background-color: ${themeColor("fontGrey")};
        transition: all ${themeTransition("fast")};

        li.slick-active > & {
            background-color: ${themeColor("blue")};
        }
    `;

    const MobileSlider = styled(Slider)`
        .slick-track {
            min-width: 500vw;
        }
    `;

    const MobileCardWrapper = styled.div`
        ${padding.x("0.75rem")};
        ${padding.y("0.1rem")};
    `;

    return function Cards(props: CardsProps) {
        const isMobile = useMediaSSR(desktop.smallerThanThis().toString(), SSRMQValues);

        return (
            <Root>
                {isMobile ? (
                    <MobileSlider
                        infinite={false}
                        dots
                        arrows={false}
                        centerMode
                        appendDots={dots => <MobileDots>{dots}</MobileDots>}
                        customPaging={i => <MobileDot />}
                        variableWidth
                        touchThreshold={100}
                    >
                        {Children.toArray(props.children).map(child => (
                            <MobileCardWrapper
                                key={isValidElement(child) && child.key !== null ? child.key : undefined}
                            >
                                {child}
                            </MobileCardWrapper>
                        ))}
                    </MobileSlider>
                ) : (
                    <DesktopCards>
                        {Children.toArray(props.children).map((child, i) => (
                            <DesktopCardWrapper
                                index={i}
                                key={isValidElement(child) && child.key !== null ? child.key : undefined}
                            >
                                {child}
                            </DesktopCardWrapper>
                        ))}
                    </DesktopCards>
                )}
            </Root>
        );
    };
})();

interface CardProps {
    title: string;
    text: string | ReactNode;
    icon: string | DynamicImageContent;
}
const Card = (() => {
    const CardRoot = styled.div`
        min-height: 350px;
        border-radius: 0.625rem;
        background-color: white;
        padding: 2rem;
    `;

    const cardBackground = css`
        --shadow: 0 1px 0 0
                ${p =>
                    Color(p.theme.palette.fontSemiLight)
                        .alpha(0.26)
                        .toString()},
            0 16px 24px 0
                ${p =>
                    Color(p.theme.palette.fontSemiLight)
                        .alpha(0.05)
                        .toString()};
        box-shadow: var(--shadow);
        border-radius: 0.625rem;
        background-color: white;
    `;

    const Root = styled.div`
        width: 250px;
        text-align: center;
        padding-top: 1.5rem;

        @media ${desktop.smallerThanThis().toString()} {
            ${cardBackground}
        }
        @media ${desktop.andUp().toString()} {
            ${CardRoot} {
                ${cardBackground}
            }
        }
    `;
    const Caption = styled.h3`
        color: ${themeColor("fontSemiDark")};
        ${zeplinLerp("font-size", 16, 24)};
        ${zeplinLerp("line-height", 32, 24)};
        margin-bottom: 2rem;

        @media ${desktop.smallerThanThis().toString()} {
            text-transform: uppercase;
        }
    `;

    const IconWrapper = styled.div`
        height: 7.5rem;
        margin-bottom: 1.5rem;
    `;
    const Icon = styled(DynamicImage)`
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    `;
    const Text = styled(Markdown)`
        font-size: 1.125rem;
        line-height: 1.75rem;
        text-align: center;
        margin-bottom: 0;
        & > :last-child {
            margin-bottom: 0;
        }
    `;

    return function Card(props: CardProps) {
        return (
            <Root>
                <Caption>{props.title}</Caption>
                <CardRoot>
                    <IconWrapper>
                        <Icon {...(typeof props.icon === "string" ? { src: props.icon } : props.icon)} />
                    </IconWrapper>
                    <Text>{props.text}</Text>
                </CardRoot>
            </Root>
        );
    };
})();

const SignupSectionFragment = styled.div`
    ${rootCss}
`;
const SignupContent = styled.div`
    ${contentCss}
    text-align: center;
`;

const SignupText = styled(Markdown)`
    display: inline-block;
    font-style: italic;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2rem;
    ${margin.x("1rem")};
`;
const SignupButton = styled(ButtonLink)`
    ${p => shadowedButton({ color: p.theme.palette.orange })};
    color: white;
    padding: 1.5rem 3.375rem;
`;

function Section04() {
    const content = useContext(ContentContext).home.section04;
    return (
        <Root id="how-it-works">
            <HeadingSectionFragment>
                <HeadingContent>
                    <Heading>{content.title}</Heading>
                    <Text>{content.text}</Text>
                </HeadingContent>
            </HeadingSectionFragment>
            <Cards>
                {content.cards.map(card => (
                    <Card {...card} />
                ))}
            </Cards>
        </Root>
    );
}
export default Section04;
