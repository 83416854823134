import ButtonLink from "components/button-link";
import DynamicImage from "components/dynamic-image";
import { DynamicImageContent } from "declarations/content";
import { ContentContext } from "hooks/use-content";
import useMediaSSR from "hooks/use-media-ssr";
import city from "images/home/section05/city.svg";
import sunAndCloud from "images/home/section05/sun-and-cloud.svg";
import tree from "images/home/section05/tree.svg";
import React, { ReactNode, useContext } from "react";
import SSRMQValues from "services/ssr-mq-values";
import styled, { css } from "styled-components";
import { desktop, mobile } from "styles/media-queries";
import shadowedButton from "styles/shadowed-button";
import themeColor from "styles/theme-color";
import { padding } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";
import Markdown from "components/markdown";

const LeftPane = styled.div`
    position: relative;
    background-color: ${themeColor("blue")};
    background-image: url(${city});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${desktop.smallerThanThis().toString()} {
        padding-bottom: 6rem;
    }
    @media ${desktop.andUp().toString()} {
        padding-bottom: 4rem;
    }

    ::before {
        content: "";
        position: absolute;

        background-image: url(${tree});
        background-size: 100% 100%;
        @media ${desktop.smallerThanThis().toString()} {
            right: 14px;
            bottom: -34px;
            width: 85px;
            height: 162px;
        }
        @media ${desktop.andUp().toString()} {
            left: 77px;
            bottom: -34px;
            width: 85px;
            height: 162px;
        }
    }
`;

const LeftPaneContent = (() => {
    const Root = styled.div`
        display: flex;
        flex-direction: column;
        ${padding.x("10%")};
    `;

    const Header = styled(Markdown)`
        width: 70%;
        margin: 0 auto;
        text-align: center;
        font-weight: bold;
        color: white;
        ${zeplinLerp("font-size", 40, 66)};
        line-height: 1;
        ${zeplinLerp("margin-bottom", 18, 32)};
        ${zeplinLerp("margin-top", 24, 40)};

        @media ${mobile.andDown().toString()} {
            width: 100%;
        }
    `;

    const ImageContainer = styled.div`
        display: flex;
        align-items: center;

        @media ${desktop.smallerThanThis().toString()} {
            flex-direction: column;
        }
    `;

    const Image = styled(DynamicImage)`
        width: 50%;

        @media ${mobile.andDown().toString()} {
            width: 100%;
            margin-bottom: 24px;
        }
    `;

    const Description = styled(Markdown)`
        flex-shrink: 0;
        text-align: center;
        font-weight: bold;
        color: white;
        ${zeplinLerp("font-size", 24, 40)};
        line-height: 1;
        ${zeplinLerp("margin-bottom", 24, 40)};
        width: 50%;

        @media ${mobile.andDown().toString()} {
            width: 100%;
        }
    `;

    type LeftPaneContentProps = {
        shirtImage: string | DynamicImageContent;
        header: string;
        description: string;
    };

    return function LeftPaneContent(props: LeftPaneContentProps) {
        return (
            <Root>
                <Header>{props.header}</Header>
                <ImageContainer>
                    <Image {...(typeof props.shirtImage === "string" ? { src: props.shirtImage } : props.shirtImage)} />
                    <Description>{props.description}</Description>
                </ImageContainer>
            </Root>
        );
    };
})();

interface PrizeItemProps {
    image: string | DynamicImageContent;
    index: number;
    text1?: ReactNode;
    text2?: ReactNode;
    text3?: ReactNode;
}
const PrizeItem = (() => {
    const Root = styled.li`
        list-style: none;
        display: flex;
        align-items: center;
        ${padding.y("2.5rem")};
        padding-left: 3rem;

        --border: 2px solid #ebecf0;
        border-top: var(--border);
        &:last-of-type {
            border-bottom: var(--border);
        }

        @media ${mobile.andDown().toString()} {
            ${padding.x("1.5rem")};
        }
    `;

    const ItemIndex = styled.div`
        flex-shrink: 0;
        border-radius: 50%;
        border: 1px solid #dcdde0;
        --size: 2.25rem;
        width: var(--size);
        height: var(--size);
        margin-right: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem;
        line-height: 1;

        @media ${mobile.andDown().toString()} {
            margin: 0 0 1rem;
        }
    `;

    const ImageWrapper = styled.div`
        flex-shrink: 0;
        width: 100px;
        margin-right: 2.5rem;

        @media ${mobile.andDown().toString()} {
            margin-right: 0;
        }
    `;
    const Image = styled(DynamicImage)`
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    `;
    const TextContainer = styled.div``;

    const HtmlMarkdown = props => <Markdown customizeMarkdownIt={md => md.set({ html: true })} {...props} />;

    const textCss = css`
        font-weight: bold;

        p {
            margin: 0;
        }
    `;

    const Text1 = styled(HtmlMarkdown)`
        ${textCss};
        color: ${themeColor("fontSemiDark")};
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
    `;

    const Text2 = styled(HtmlMarkdown)`
        ${textCss};
        ${zeplinLerp("font-size", 16, 18)};
        margin-bottom: 0.25rem;
    `;

    const Text3 = styled(HtmlMarkdown)`
        ${textCss};
        font-size: 2rem;
        color: ${themeColor("fontSemiDark")};
        margin-bottom: 0;
    `;

    const PrizeImageContainer = styled.div`
        display: flex;
        align-items: center;

        @media ${mobile.andDown().toString()} {
            flex-direction: column;
            margin-right: 1.5rem;
        }
    `;

    return function PrizeItem(props: PrizeItemProps) {
        return (
            <Root>
                <PrizeImageContainer>
                    <ItemIndex>{props.index + 1}</ItemIndex>
                    <ImageWrapper>
                        <Image {...(typeof props.image === "string" ? { src: props.image } : props.image)} />
                    </ImageWrapper>
                </PrizeImageContainer>
                <TextContainer>
                    <Text1>{props.text1}</Text1>
                    <Text2>{props.text2}</Text2>
                    <Text3>{props.text3}</Text3>
                </TextContainer>
            </Root>
        );
    };
})();

const RightPane = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-inline-start: 0;
    margin-bottom: 0;
    background-color: white;
    & > * {
        flex: 1;
    }
`;

const Root = styled.section`
    display: flex;

    @media ${desktop.andUp().toString()} {
        --l-to-r-ratio: calc(2.5 / 1.5);

        ${LeftPane} {
            flex: var(--l-to-r-ratio) 0 0;
        }
        ${RightPane} {
            flex: 1 0 0;
        }
    }
    @media ${desktop.smallerThanThis().toString()} {
        flex-direction: column;
    }
`;

const SignupButton = styled(ButtonLink)`
    ${shadowedButton({ color: "white" })};
    padding: 1rem 3.375rem;

    @media ${desktop.andUp().toString()} {
        position: absolute;
        right: 3rem;
        bottom: 4.5rem;
    }
`;

const LeftPaneImage = styled.object`
    display: block;

    width: 100%;
    object-fit: contain;
    object-position: center;

    @media ${desktop.andUp().toString()} {
        height: 700px;
    }

    @media ${desktop.smallerThanThis().toString()} {
        height: 600px;
        max-height: 100vh;
        margin-bottom: 3rem;
    }
`;

const LeftPaneAdornment = styled.img.attrs({
    src: sunAndCloud,
})`
    position: absolute;

    @media ${desktop.andUp().toString()} {
        right: 77px;
        top: 70px;
        width: 112px;
        height: auto;
    }

    @media ${desktop.smallerThanThis().toString()} {
        right: 32px;
        top: -32px;
        width: 112px;
        height: auto;
    }
`;

function Section05() {
    const content = useContext(ContentContext).home.section05;

    return (
        <Root>
            <LeftPane>
                <LeftPaneAdornment />
                <LeftPaneContent
                    shirtImage={content.left.shirtImage}
                    description={content.left.description}
                    header={content.left.header}
                />
                <SignupButton to={content.signUpButton.link}>{content.signUpButton.text}</SignupButton>
            </LeftPane>
            <RightPane>
                {content.prizes.map((prize, i) => (
                    <PrizeItem key={prize.text2} index={i} {...prize} />
                ))}
            </RightPane>
        </Root>
    );
}
export default Section05;
