import Layout from "components/layout";
import SEO from "components/seo";
import Section00 from "parts/home/Section00";
import Section01 from "parts/home/Section01";
import Section02 from "parts/home/Section02";
import Section04 from "parts/home/Section04";
import Section05 from "parts/home/Section05";
import Section06 from "parts/home/Section06";
import Section07 from "parts/home/Section07";
import Section08 from "parts/home/Section08";
import Section09 from "parts/home/Section09";
import Section12 from "parts/home/Section12";
import Section13 from "parts/home/Section13";
import Section14 from "parts/home/Section14";
import Section16 from "parts/home/Section16";
import React from "react";

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <Section00 />
        <Section01 />
        <Section02 />
        <Section04 />
        <Section05 />
        <Section06 />
        <Section07 />
        <Section08 />
        <Section09 />
        <Section12 />
        <Section13 />
        <Section14 />
        <Section16 />
    </Layout>
);

export default IndexPage;
