import Slider from "components/slider";
import dropProps from "hoc/drop-props";
import { ContentContext } from "hooks/use-content";
import useMediaSSR from "hooks/use-media-ssr";
import background from "images/home/section08/background-desktop.svg";
import backgroundFlora from "images/home/section08/background-flora.svg";
import backgroundMobile from "images/home/section08/background-mobile-blue.svg";
import pointsBg from "images/home/section08/points-bg.svg";
import sun from "images/home/section08/sun.svg";
import React, { useContext, useEffect, useRef, useState } from "react";
import SSRMQValues from "services/ssr-mq-values";
import styled, { css } from "styled-components";
import { desktop, mobile } from "styles/media-queries";
import themeColor from "styles/theme-color";
import themeTransition from "styles/theme-transition";
import { childSpacing, contentCss, margin, padding, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section`
    ${rootCss};
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom 200px;
    color: white;

    @media ${desktop.smallerThanThis().toString()} {
        background-size: 1200px auto;
        background-position: center bottom 550px;
        background-image: url(${backgroundMobile});
    }
`;

const Content = styled.div`
    ${contentCss};
    ${margin.centerX};
    padding-top: 16rem;
    padding-bottom: 8rem;

    @media ${desktop.smallerThanThis().toString()} {
        margin-left: -2rem;
        width: calc(100% + 4rem);
        max-width: unset;

        > :first-child {
            margin-bottom: 391px;
        }
    }
`;

const Header = styled.h2`
    margin-block-start: 0;

    ${zeplinLerp("font-size", 24, 48)};
    ${zeplinLerp("line-height", 32, 72)};

    @media ${desktop.andUp().toString()} {
        /* card's width */
        margin-right: calc(246 / 16 * 1rem);
        /* card's y offset */
        margin-bottom: -3rem;
    }

    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
        max-width: 60%;
        ${margin.centerX};
        @media ${mobile.andDown().toString()} {
            max-width: 300px;
        }
    }
`;

const AlignRightOnDesktop = styled.div`
    @media ${desktop.andUp().toString()} {
        display: flex;
        justify-content: flex-end;
    }
`;
const Cards = styled.div`
    @media ${desktop.andUp().toString()} {
        display: grid;
        grid-template-columns: auto auto;
        gap: calc(24 / 16 * 1rem);

        > :nth-child(odd) {
            position: relative;
            top: calc(105 / 16 * 1rem);
        }
    }
    @media ${desktop.smallerThanThis().toString()} {
        display: flex;
        ${childSpacing({ horizontal: "2rem" })};
        overflow-x: auto;
    }
`;

const Card = styled.div(
    p => css`
        border-radius: 1rem;
        background-color: #303d80;
        --card-width-raw: 246px;
        --card-height-raw: 299px;
        --card-width: calc(var(--card-width-raw));
        --card-height: calc(var(--card-height-raw));
        width: var(--card-width);
        height: var(--card-height);
        min-width: var(--card-width);
        min-height: var(--card-height);
        box-shadow: 0 8px 16px 0 rgba(210, 216, 224, 0.05);
    `,
);

const CardIcon = (() => {
    const Root = styled(dropProps("variant")("div"))<{ variant: "sm" | "lg" }>(p =>
        p.variant === "lg"
            ? css`
                  border-radius: inherit;
                  ${padding.y("3rem")};
                  background-color: white;
                  color: ${themeColor("fontDark")};
              `
            : css`
                  margin: 2rem;
                  margin-bottom: 0;
                  padding: 3rem;
                  height: 10rem;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-image: url(${pointsBg});
              `,
    );

    const Content = styled.div`
        color: ${themeColor("fontDark")};
        text-align: center;
    `;

    const Points = styled.span`
        display: block;
        font-weight: bold;
        font-size: calc(48 / 16 * 1rem);
        line-height: calc(56 / 48);
    `;
    const PointsText = styled.span`
        display: block;
        color: ${themeColor("fontSemiDark")};
        font-weight: normal;
        font-size: calc(16 / 16 * 1rem);
        line-height: calc(20 / 16);
    `;

    interface CardIconProps {
        variant: "sm" | "lg";
        points: string;
        pointsText: string;
    }
    return function CardIcon(props: CardIconProps) {
        return (
            <Root variant={props.variant}>
                <Content>
                    <Points>{props.points}</Points>
                    <PointsText>{props.pointsText}</PointsText>
                </Content>
            </Root>
        );
    };
})();

const CardBody = styled.div`
    padding: 1rem;
`;

const CardBodyText = styled.h3`
    text-align: center;
    font-size: calc(18 / 16 * 1rem);
    line-height: calc(32 / 18);
`;

const FooterInfo = styled.p(
    p => css`
        font-weight: bold;
        ${zeplinLerp("font-size", 12, 14)};
        ${zeplinLerp("line-height", 20, 32)};
        color: ${themeColor("fontSemiLight")};
        max-width: 500px;

        @media ${desktop.andUp().toString()} {
            /* 2x card's width + additional margin */
            margin-right: calc(2 * 246 / 16 * 1rem + 4rem);
        }
        @media ${desktop.smallerThanThis().toString()} {
            ${padding.x("2rem")};
        }
    `,
);

const Statics = styled.div`
    position: relative;
    height: 0;
    > * {
        position: absolute;
    }
`;

const Sun = styled.div`
    position: absolute;
    --sizecalc: 89 / 16 * 1rem;
    width: calc(var(--sizecalc));
    height: calc(var(--sizecalc));
    background-image: url(${sun});
    background-size: cover;
    top: calc(var(--sizecalc) * -0.5);
    right: calc(124 / 16 * 1rem);
`;

const Flora = styled.div`
    background-image: url(${backgroundFlora});
    width: 145px;
    height: 289px;
    background-size: 100% 100%;
    right: 124px;
    bottom: -86px;
`;

const StyledSlider = styled(Slider)`
    .slick-track {
        min-width: calc(4 * 278px);
    }
`;

const MobileCardWrapper = styled.div`
    display: inline;
    ${padding.x("1rem")}
    > :only-child {
        display: inline-block;
    }
`;

const DotsContainer = styled.div`
    list-style: none;
    display: flex;
    justify-content: center;
    ${childSpacing({ horizontal: "0.5rem" })};
    ${padding.y("2rem")};
`;

const Dot = styled.div`
    border-radius: 50%;
    background-color: ${themeColor("fontGrey")};
    transition: all ${themeTransition("fast")};
    width: 0.5rem;
    height: 0.5rem;

    li.slick-active > & {
        background-color: ${themeColor("blue")};
    }
`;

function Section08() {
    const content = useContext(ContentContext).home.section08;
    const isDesktop = useMediaSSR(desktop.andUp().toString(), SSRMQValues);

    const customOrdering = isDesktop ? content.orderDesktop : content.orderMobile;
    const orderedCards = customOrdering ? customOrdering.map(o => content.cards[o]) : content.cards;

    const cards = orderedCards.map(card => (
        <Card key={card.points}>
            <CardIcon
                variant={card.big ? "lg" : "sm"}
                points={card.points.toString()}
                pointsText={card.pointsSecondary}
            />
            <CardBody>
                <CardBodyText>{card.caption}</CardBodyText>
            </CardBody>
        </Card>
    ));

    const sliderRef = useRef<Slider | null>(null);
    const [sliderState, setSliderState] = useState(0);

    useEffect(() => {
        sliderRef.current && sliderRef.current.slickGoTo(sliderState);
    }, [sliderRef, sliderState]);

    return (
        <Root>
            <Content>
                <Header>{content.header}</Header>
                <AlignRightOnDesktop>
                    {isDesktop ? (
                        <Cards>{cards}</Cards>
                    ) : (
                        <>
                            <StyledSlider
                                ref={sliderRef}
                                arrows={false}
                                afterChange={setSliderState}
                                infinite={false}
                                variableWidth
                                centerMode
                                swipeToSlide
                                touchThreshold={100}
                                dots
                                appendDots={dots => <DotsContainer>{dots}</DotsContainer>}
                                customPaging={index => <Dot />}
                            >
                                {cards.map(card => (
                                    <MobileCardWrapper key={card.key!}>{card}</MobileCardWrapper>
                                ))}
                            </StyledSlider>
                        </>
                    )}
                </AlignRightOnDesktop>
                <FooterInfo>{content.footer}</FooterInfo>
            </Content>
        </Root>
    );
}
export default Section08;
