import { differenceInDays, differenceInHours, differenceInMinutes, subDays, subHours } from "date-fns";
import { useCallback, useEffect, useState } from "react";

export default function useRemainingTime(date: number | Date) {
    const calculateRemainingTime = useCallback(() => {
        const now = Date.now();
        const days = differenceInDays(date, now);
        const hours = differenceInHours(subDays(date, days), now);
        const minutes = differenceInMinutes(subHours(subDays(date, days), hours), now);
        return {
            days,
            hours,
            minutes,
        };
    }, [date]);

    const [remaining, setRemaining] = useState<{ days: number; hours: number; minutes: number }>(
        calculateRemainingTime,
    );

    useEffect(() => {
        const timer = setInterval(() => {
            setRemaining(calculateRemainingTime());
        }, 60 * 1000);
        return () => {
            clearInterval(timer);
        };
    }, [calculateRemainingTime, setRemaining]);

    return remaining;
}
