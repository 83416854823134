import DynamicImage from "components/dynamic-image";
import Markdown from "components/markdown";
import { ContentContext } from "hooks/use-content";
import arrow from "images/home/section16/arrow.svg";
import React, { useContext } from "react";
import styled from "styled-components";
import { desktop } from "styles/media-queries";
import themeColor from "styles/theme-color";
import themeTransition from "styles/theme-transition";
import { childSpacing, margin, padding, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";
import useMediaSSR from "hooks/use-media-ssr";
import SSRMQValues from "services/ssr-mq-values";

const Root = styled.section`
    background-color: #ffedd7;
`;

const Content = styled.div`
    display: flex;

    @media ${desktop.smallerThanThis().toString()} {
        flex-direction: column-reverse;
    }
`;

const CardContainer = styled.div`
    ${padding.x("25px")};
    ${padding.y("20px")};
    ${margin.centerX};

    @media (min-width: 1450px) {
        ${padding.y("80px")};
        ${padding.x("2rem")};
    }
`;

const CardRoot = styled.div`
    ${zeplinLerp("width", 300, 520)};
    box-shadow: 0 1px 0 0 rgba(163, 173, 186, 0.26), 0 40px 72px 0 rgba(255, 164, 55, 0.1);

    border-radius: 1rem;
    overflow: hidden;
    background-color: white;

    @media ${desktop.andUp().toString()} {
        position: relative;
        z-index: 1;
    }
    @media ${desktop.smallerThanThis().toString()} {
        position: relative;
        top: -3rem;
        z-index: 1;
    }
`;
const CardHeader = styled.div`
    background-color: ${themeColor("orange")};
    color: white;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 104px;
`;
const CardHeading = styled.h3`
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0;
`;
const CardBody = styled.div`
    padding: 1.5rem;
    font-size: 9px;

    h2 {
        font-size: 20px;
        font-weight: normal;
    }
`;
const CardBodyContent = styled(Markdown)`
    text-align: center;

    & > p > img {
        width: 100%;
        object-fit: contain;
    }
`;

const ImageLinkArrow = styled.img.attrs({
    src: arrow,
})`
    transition: all ${themeTransition("fast")};
`;
const ImageLink = styled.a`
    display: block;
    align-self: stretch;
    width: 100%;
    position: relative;
    text-decoration: inherit;
    color: white;

    &:hover ${ImageLinkArrow} {
        transform: translateX(16px);
    }
`;

const ImageLinkImage = styled(DynamicImage)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

const ImageLinkOverlay = styled.div`
    ${rootCss};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to top, #0004, transparent 50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;
const ImageLinkTextContainer = styled.div`
    max-width: 420px;

    padding-bottom: 80px;

    @media ${desktop.andUp().toString()} {
        margin-left: 200px;
    }
`;
const ImageLinkText = styled(Markdown)`
    font-size: 2rem;
    font-weight: bold;
`;
const ImageLinkLink = styled.span`
    display: block;
    font-size: 1.25rem;
    ${childSpacing({ horizontal: "2ch" })};
`;

const ImageLinkIcon = styled(DynamicImage)`
    display: block;
    @media ${desktop.andUp().toString()} {
        position: absolute;
        top: 72px;
        right: 72px;
    }
    width: 120px;
`;

function Section16() {
    const content = useContext(ContentContext).home.section16;

    const isMobile = useMediaSSR(desktop.smallerThanThis().toString(), SSRMQValues);
    const backgroundImage = isMobile ? content.backgroundImage.mobile : content.backgroundImage.desktop;

    return (
        <Root>
            <Content>
                <CardContainer>
                    <CardRoot>
                        <CardHeader>
                            <CardHeading>{content.card.title}</CardHeading>
                        </CardHeader>
                        <CardBody>
                            <CardBodyContent>{content.card.text}</CardBodyContent>
                        </CardBody>
                    </CardRoot>
                </CardContainer>
                <ImageLink>
                    {/* <ImageLinkOverlay>
                        <ImageLinkIcon {...(typeof content.logo === "string" ? { src: content.logo } : content.logo)} />
                        <ImageLinkTextContainer>
                            <ImageLinkText>{content.text}</ImageLinkText>
                            <ImageLinkLink>
                                <span>{content.button.text}</span>
                                <ImageLinkArrow />
                            </ImageLinkLink>
                        </ImageLinkTextContainer>
                    </ImageLinkOverlay> */}
                    <ImageLinkImage
                        {...(typeof backgroundImage === "string" ? { src: backgroundImage } : backgroundImage)}
                    />
                </ImageLink>
            </Content>
        </Root>
    );
}
export default Section16;
