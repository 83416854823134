import Color from "color";
import { ContentContext } from "hooks/use-content";
import useRemainingTime from "hooks/use-remaining-time";
import backgroundDesktop from "images/home/section02/background-desktop.svg";
import backgroundMobile from "images/home/section02/background-mobile.svg";
import React, { useContext } from "react";
import styled from "styled-components";
import { desktop } from "styles/media-queries";
import themeColor from "styles/theme-color";
import { childSpacing, contentCss, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section`
    ${rootCss};
    ${zeplinLerp("padding-top", 40, 80)};
    ${zeplinLerp("padding-bottom", 220, 300)};
    background-repeat: no-repeat;
    background-image: url(${backgroundDesktop});
    background-position: center bottom;
    background-color: #ffffff;

    @media ${desktop.smallerThanThis().toString()} {
        background-image: url(${backgroundMobile});
    }
`;

const Content = styled.div`
    ${contentCss};
    --preferred-content-width: 700px;
`;

const Text = styled.h2`
    ${zeplinLerp("font-size", 24, 40)};
    ${zeplinLerp("line-height", 32, 48)};
    text-align: center;

    ${zeplinLerp("margin-bottom", 24, 80)};
`;
const CounterRoot = styled.div``;
const CounterHeader = styled.h3`
    text-align: center;
    ${zeplinLerp("font-size", 16, 25)};
    line-height: 2rem;
    color: ${p => p.theme.palette.fontSemiDark};
`;
const CounterCards = styled.div`
    display: flex;
    justify-content: center;
    ${zeplinLerp("--spacing", 14.5, 24)};
    ${childSpacing({ horizontal: "var(--spacing)" })};
`;
const CounterCardRoot = styled.figure`
    margin-inline-start: 0;
    margin-inline-end: 0;
`;
const CounterCardMain = styled.div`
    display: flex;
    ${zeplinLerp("--card-size", 94, 156)};
    width: var(--card-size);
    height: var(--card-size);
    border-radius: 0.625rem;
    background-image: linear-gradient(
        to top,
        ${themeColor("blue")},
        ${p =>
            Color(p.theme.palette.blue)
                .mix(Color("white"))
                .toString()}
    );
    background-size: 50% 50%;
    box-shadow: 0 4px 0 0
        ${p =>
            Color(p.theme.palette.blue)
                .darken(0.1)
                .toString()};

    color: #fffd;
    justify-content: center;
    align-items: center;
    font-size: calc(var(--card-size) / 2);
    line-height: 1;

    ${zeplinLerp("margin-bottom", 19.6, 25.3)};
`;
const CounterCardCaption = styled.figcaption`
    color: ${themeColor("fontGrey")};
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    ${zeplinLerp("font-size", 14, 16)};
    ${zeplinLerp("letter-spacing", 1.75, 2)};
`;

function Section02() {
    const content = useContext(ContentContext).home.section02;

    const date = Date.parse(content.counter.date);
    const remaining = useRemainingTime(date);

    return (
        <Root>
            <Content>
                <Text>{content.text}</Text>
                <CounterRoot>
                    <CounterHeader>{content.counter.header}</CounterHeader>
                    <CounterCards>
                        <CounterCardRoot>
                            <CounterCardMain>{remaining.days}</CounterCardMain>
                            <CounterCardCaption>{content.counter.daysCaption}</CounterCardCaption>
                        </CounterCardRoot>
                        <CounterCardRoot>
                            <CounterCardMain>{remaining.hours}</CounterCardMain>
                            <CounterCardCaption>{content.counter.hoursCaption}</CounterCardCaption>
                        </CounterCardRoot>
                        <CounterCardRoot>
                            <CounterCardMain>{remaining.minutes}</CounterCardMain>
                            <CounterCardCaption>{content.counter.minutesCaption}</CounterCardCaption>
                        </CounterCardRoot>
                    </CounterCards>
                </CounterRoot>
            </Content>
        </Root>
    );
}
export default Section02;
