import ButtonLink from "components/button-link";
import Markdown from "components/markdown";
import { ContentContext } from "hooks/use-content";
import city from "images/home/section13/city.svg";
import tree from "images/home/section13/tree.svg";
import React, { useContext } from "react";
import styled from "styled-components";
import { desktop, mobileUltraSm } from "styles/media-queries";
import shadowedButton from "styles/shadowed-button";
import { contentCss, padding, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section`
    ${rootCss};
    ${zeplinLerp("padding-top", 24, 32)};
    ${zeplinLerp("padding-bottom", 120, 80)};
`;

const Content = styled.div`
    ${contentCss}
`;

const Text = styled(Markdown)`
    ${zeplinLerp("font-size", 20, 24)};
    ${zeplinLerp("line-height", 32, 40)};
    font-weight: bold;

    & > :last-child {
        margin-bottom: 0;
    }
`;

const Button = styled(ButtonLink)`
    ${p => shadowedButton({ color: p.theme.palette.orange })};
    color: white;
    padding: 1.5rem 1.5rem;
    font-weight: bold;

    @media ${mobileUltraSm.biggerThanThis().toString()} {
        white-space: nowrap;
    }
`;

const Box = styled.div`
    border-radius: 1.5rem;
    background-color: white;
    background-image: url(${city});
    background-repeat: no-repeat;
    background-position: center bottom 1.5rem;
    ${zeplinLerp("--bg-size", 300, 600)};
    background-size: var(--bg-size) auto;

    ${zeplinLerp("--padding-x", 32, 72)};
    ${padding.x("var(--padding-x)")};
    ${zeplinLerp("padding-top", 32, 70)};
    ${zeplinLerp("padding-bottom", 108, 70)};

    @media ${desktop.andUp().toString()} {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;

        ${Text} {
            margin-bottom: 1.25rem;
        }
    }

    position: relative;
    ::after {
        content: "";
        position: absolute;
        background-image: url(${tree});
        background-size: 100% 100%;
        width: 105px;
        height: 182px;

        @media ${desktop.andUp().toString()} {
            right: -50px;
            bottom: -48px;
        }
        @media ${desktop.smallerThanThis().toString()} {
            right: 7px;
            bottom: -100px;
        }
    }
`;

function Section13() {
    const content = useContext(ContentContext).home.section13;
    return (
        <Root>
            <Content>
                <Box>
                    <Text>{content.text}</Text>
                    <Button to={content.button.link}>{content.button.text}</Button>
                </Box>
            </Content>
        </Root>
    );
}
export default Section13;
