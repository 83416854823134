import { useEffect, useState } from "react";
import useIsClient from "./use-is-client";

export interface WindowSize {
    innerWidth: number;
    innerHeight: number;
}
function useWindowSize(ssrDefaults: WindowSize): WindowSize {
    useIsClient();
    const [size, setSize] = useState(ssrDefaults);

    useEffect(() => {
        const callback = () => {
            setSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
        };
        addEventListener("resize", callback);
        return () => {
            removeEventListener("resize", callback);
        };
    }, []);

    return size;
}
export default useWindowSize;
