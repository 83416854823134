import color from "color";
import ButtonLink from "components/button-link";
import Markdown from "components/markdown";
import { ContentContext } from "hooks/use-content";
import useRemainingTime from "hooks/use-remaining-time";
import cloud from "images/common/cloud.svg";
import bottomBackground from "images/home/section14/bottom-background.svg";
import bottomPlane from "images/home/section14/bottom-plane.svg";
import city from "images/home/section14/city.svg";
import sunAndCloud from "images/home/section14/sun-and-cloud.svg";
import tree1 from "images/home/section14/tree1.svg";
import tree2 from "images/home/section14/tree2.svg";
import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { desktop, mobile } from "styles/media-queries";
import shadowedButton from "styles/shadowed-button";
import themeColor from "styles/theme-color";
import themeTransition from "styles/theme-transition";
import { childSpacing, contentCss, margin, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const grid = css`
    display: grid;
    @media ${desktop.andUp().toString()} {
        grid-template-areas:
            "card timer"
            "card text";
        column-gap: 7.5rem;
        row-gap: 2.5rem;
        grid-template-rows: auto 1fr;
    }
    @media ${desktop.smallerThanThis().toString()} {
        grid-template-areas:
            "timer"
            "card"
            "text";
        justify-items: center;
        row-gap: 3.5rem;
        grid-template-columns: 100%;
    }
`;

const Root = styled.section`
    @media ${desktop.andUp().toString()} {
        ${rootCss}
    }
    background-color: white;
    border-top: 2px solid ${themeColor("disabled")};
    ${zeplinLerp("padding-top", 40, 120)};
    ${zeplinLerp("padding-bottom", 40, 120)};
`;

const Content = styled.div`
    position: relative;

    @media ${desktop.andUp().toString()} {
        ${contentCss}
        background-image: url(${tree2}), url(${city});
        background-repeat: no-repeat;
        background-position: right 100px bottom 100px, center bottom 150px;
        background-size: 140px 242px, 100% 250px;
    }

    ${grid}
`;

const CardRoot = styled.div`
    grid-area: card;
`;

const TimerRoot = styled.div`
    grid-area: timer;
    position: relative;

    @media ${desktop.andUp().toString()} {
        padding-top: 4.5rem;
    }
`;

const TextRoot = styled.div`
    grid-area: text;

    @media ${desktop.smallerThanThis().toString()} {
        ${rootCss};
        ${margin.centerX};
        text-align: center;
    }
`;

// #region [ rgba(0, 255, 255, 0.05) ] Timer

const TimerHeader = styled.hgroup`
    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;
const TimerHeading = styled.h3`
    font-size: 2rem;
    line-height: 1;
    margin-bottom: 0;
`;
const TimerSubheading = styled.h4`
    font-size: 1rem;
    line-height: 2;
    color: ${themeColor("fontSemiDark")};
`;
const TimerCards = styled.div`
    display: flex;
    justify-content: center;
    ${zeplinLerp("--spacing", 14.5, 24)};
    ${childSpacing({ horizontal: "var(--spacing)" })};

    @media ${desktop.andUp().toString()} {
        justify-content: flex-start;
    }
`;
const TimerCardRoot = styled.figure`
    margin-inline-start: 0;
    margin-inline-end: 0;
`;
const TimerCardMain = styled.div`
    display: flex;
    ${zeplinLerp("--card-size", 94, 80)};
    width: var(--card-size);
    height: var(--card-size);
    border-radius: 0.625rem;
    background-image: linear-gradient(
        to top,
        ${themeColor("blue")},
        ${p =>
            color(p.theme.palette.blue)
                .mix(color("white"))
                .toString()}
    );
    background-size: 50% 50%;
    box-shadow: 0 4px 0 0
        ${p =>
            color(p.theme.palette.blue)
                .darken(0.1)
                .toString()};

    color: #fffd;
    justify-content: center;
    align-items: center;
    font-size: calc(var(--card-size) / 2);
    line-height: 1;

    ${zeplinLerp("margin-bottom", 19.6, 25.3)};
`;
const TimerCardCaption = styled.figcaption`
    color: ${themeColor("fontGrey")};
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    ${zeplinLerp("font-size", 14, 16)};
    ${zeplinLerp("letter-spacing", 1.75, 2)};
`;

// #endregion

const Text = styled(Markdown)`
    color: ${themeColor("fontGrey")};
    ${zeplinLerp("font-size", 14, 16)};
    ${zeplinLerp("line-height", 24, 28)};
    max-width: 400px;
`;

// #region [ rgba(0, 0, 255, 0.05) ] Card

const CardWrapper = styled.div`
    width: 520px;
    max-width: 100%;
    position: relative;
    z-index: 1;

    @media ${mobile.andDown().toString()} {
        width: auto;
    }
`;

const MainCardRoot = styled.div`
    background-color: ${themeColor("blue")};
    color: white;
    border-radius: 1.25rem;
`;

const MainCardUpper = styled.div`
    border-bottom: 1px solid
        ${color("white")
            .alpha(0.2)
            .toString()};

    padding: 3.5rem 2.5rem;
    text-align: center;
`;

const MainCardUpperHeading = styled.h2`
    font-size: 2rem;
`;

const MainCardUpperNewPrice = styled.div`
    font-size: 5rem;
`;
const MainCardUpperOldPrice = styled.div`
    color: ${color("white")
        .alpha(0.5)
        .toString()};
    font-size: 2.5rem;
    font-weight: normal;
`;
const MainCardUpperOldPriceText = styled.div`
    color: ${color("white")
        .alpha(0.5)
        .toString()};
    font-size: 1.125rem;
`;

const MainCardLower = styled.div`
    padding: 3.5rem 2.5rem;
`;

const MainCardLowerList = styled.ul`
    list-style: none;
    ${childSpacing({ vertical: "1.5rem" })};
    @media ${desktop.smallerThanThis().toString()} {
        padding-inline-start: 0;
    }
`;
const MainCardLowerListItem = styled.li`
    font-size: 1.125rem;
    line-height: 2rem;

    ::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 7px;
        --border: 3px solid white;
        border-left: var(--border);
        border-bottom: var(--border);
        transform: translateY(-3px) rotate(-45deg);
        margin-right: 1.5rem;
    }
`;
const MainCardLowerListItemPrimary = styled.span`
    margin-right: 1ch;
`;
const MainCardLowerListItemSecondary = styled.span`
    color: #bcc7d5;
`;
const BottomCardRoot = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2.5rem;
    background-image: url(${bottomBackground});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center top;
    position: relative;

    @media ${desktop.smallerThanThis().toString()} {
        flex-direction: column;
        align-items: center;
        ${childSpacing({ vertical: "0.5rem" })}
    }

    ::after {
        content: "";
        position: absolute;
        background-image: url(${bottomPlane});
        width: 201px;
        height: 76px;
        background-size: 100% 100%;
        @media ${desktop.andUp().toString()} {
            right: -190px;
            bottom: 10px;
        }
        @media ${desktop.smallerThanThis().toString()} {
            left: 50%;
            bottom: -30px;
        }
    }
`;
const BottomCardText = styled(Markdown)`
    color: #3c5492;
    font-size: 1.5rem;
    line-height: 2.5rem;

    &,
    & > :last-child {
        margin-bottom: 0;
    }
`;

const BottomCardButton = styled(ButtonLink)`
    ${p => shadowedButton({ color: p.theme.palette.orange, borderRadius: "0.625rem" })};
    color: white;
    border: 4px solid white;
    padding: 1.25rem 2.75rem;
    font-size: 1rem;
    text-align: center;
    transition: all ${themeTransition("fast")};

    :hover {
        background-color: ${p =>
            color(p.theme.palette.orange)
                .darken(0.3)
                .desaturate(0.1)
                .toString()};
    }
`;

// #endregion

const Statics = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    & > * {
        position: absolute;
    }
`;

const SunAndCloud = styled.img.attrs({
    src: sunAndCloud,
})`
    position: absolute;
    width: 116px;
    height: auto;
    top: 5rem;
    right: -32px;

    @media ${mobile.andDown().toString()} {
        display: none;
    }
`;

const Tree1 = styled.img.attrs({
    src: tree1,
})`
    position: absolute;
    width: 210px;
    left: 0;
    top: 40%;
    transform: translateX(-48%) translateY(-50%);
    z-index: -1;
`;

const Tree2 = styled.img.attrs({
    src: tree2,
})``;

const Cloud = styled.img.attrs({
    src: cloud,
})`
    width: 160px;
    top: -26px;
    right: -160px;
`;

function Section14() {
    const content = useContext(ContentContext).home.section14;
    const date = Date.parse(content.timer.date);
    const remainingTime = useRemainingTime(date);

    return (
        <Root id="pricing">
            <Content>
                <CardRoot>
                    <CardWrapper>
                        <SunAndCloud />
                        <Tree1 />
                        <MainCardRoot>
                            <MainCardUpper>
                                <MainCardUpperHeading>{content.card.header}</MainCardUpperHeading>
                                <MainCardUpperNewPrice>
                                    <strong>{content.card.price}</strong>
                                </MainCardUpperNewPrice>
                                <MainCardUpperOldPrice>
                                    <s>{content.card.oldPrice}</s>
                                </MainCardUpperOldPrice>
                                <MainCardUpperOldPriceText>{content.card.oldPriceCaption}</MainCardUpperOldPriceText>
                            </MainCardUpper>
                            <MainCardLower>
                                <MainCardLowerList>
                                    {content.card.list.map(item => (
                                        <MainCardLowerListItem key={item.text}>
                                            <MainCardLowerListItemPrimary>{item.text}</MainCardLowerListItemPrimary>
                                            <MainCardLowerListItemSecondary>
                                                {item.textSecondary}
                                            </MainCardLowerListItemSecondary>
                                        </MainCardLowerListItem>
                                    ))}
                                </MainCardLowerList>
                            </MainCardLower>
                        </MainCardRoot>
                        <BottomCardRoot>
                            <BottomCardText>{content.card.signup.text}</BottomCardText>
                            <BottomCardButton to={content.card.signup.button.link}>
                                {content.card.signup.button.text}
                            </BottomCardButton>
                        </BottomCardRoot>
                    </CardWrapper>
                </CardRoot>
                <TimerRoot>
                    <Statics>
                        <Cloud />
                    </Statics>
                    <TimerHeader>
                        <TimerHeading>{content.timer.header}</TimerHeading>
                        <TimerSubheading>{content.timer.headerSecondary}</TimerSubheading>
                    </TimerHeader>
                    <TimerCards>
                        <TimerCardRoot>
                            <TimerCardMain>{remainingTime.days}</TimerCardMain>
                            <TimerCardCaption>{content.timer.daysCaption}</TimerCardCaption>
                        </TimerCardRoot>
                        <TimerCardRoot>
                            <TimerCardMain>{remainingTime.hours}</TimerCardMain>
                            <TimerCardCaption>{content.timer.hoursCaption}</TimerCardCaption>
                        </TimerCardRoot>
                        <TimerCardRoot>
                            <TimerCardMain>{remainingTime.minutes}</TimerCardMain>
                            <TimerCardCaption>{content.timer.minutesCaption}</TimerCardCaption>
                        </TimerCardRoot>
                    </TimerCards>
                </TimerRoot>
                {/* <TextRoot>
                    <Text>{content.timer.text}</Text>
                </TextRoot> */}
            </Content>
        </Root>
    );
}
export default Section14;
