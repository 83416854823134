import Markdown from "components/markdown";
import Slider from "components/slider";
import { ContentContext } from "hooks/use-content";
import useMediaSSR from "hooks/use-media-ssr";
import useWindowSize from "hooks/use-window-size";
import arrowRight from "images/home/section12/arrow-right.svg";
import dotGrid from "images/home/section12/dot-grid.svg";
import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import SSRMQValues from "services/ssr-mq-values";
import styled, { css } from "styled-components";
import { desktop } from "styles/media-queries";
import themeColor from "styles/theme-color";
import themeTransition from "styles/theme-transition";
import { childSpacing, contentCss, margin, padding, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";

const Root = styled.section<{ subsectionHeight: string; subsectionCount: number }>(
    p => css`
        ${rootCss};
        color: ${p.theme.palette.fontGrey};

        @media ${desktop.andUp().toString()} {
            height: calc(100vh + ${p.subsectionCount} * ${p.subsectionHeight});
        }
    `,
);

const Panes = styled.div`
    @media ${desktop.andUp().toString()} {
        display: flex;
        background-image: linear-gradient(to right, transparent 80%, white 80%);
        ${childSpacing({ horizontal: "10rem" })};
        > :first-child {
            flex: 1;
        }
        > :last-child {
            flex: 2;
        }
    }
`;

const Content = styled.div(
    p => css`
        ${contentCss};

        @media ${desktop.andUp().toString()} {
            position: sticky;
            top: 0;
            height: 100vh;
            display: flex;
            align-items: center;
        }
    `,
);

const MainPane = styled.div`
    padding-top: 4rem;
`;

const CardPane = styled.div(
    p => css`
        background-color: white;
        border-radius: 2rem;
        padding: 4rem;
        padding-right: 0;
        @media ${desktop.smallerThanThis().toString()} {
            padding: 2rem;
        }
        position: relative;

        ${zeplinLerp("--spacing", 64, 96)};
        ${childSpacing({ vertical: "var(--spacing)" })};

        @media ${desktop.andUp().toString()} {
            ::before {
                content: "";
                position: absolute;
                top: 0;
                left: 100%;
                bottom: 0;
                width: 30vw;
                background-color: white;
                z-index: -1;
            }

            ::after {
                content: "";
                position: absolute;
                background-image: url(${dotGrid});
                width: 232px;
                height: 222px;
                background-size: 100% 100%;
                left: -50px;
                top: 50px;
                z-index: -1;
            }
        }
    `,
);

const Title = styled.h3(
    p => css`
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 2rem;
        letter-spacing: 2px;

        text-transform: uppercase;
    `,
);

const Header = styled.h2(
    p => css`
        font-size: 2.5rem;
        line-height: 4rem;
        color: ${themeColor("fontDark")};
    `,
);

const Text = styled.p(
    p => css`
        font-size: 1.5rem;
        line-height: 2rem;
    `,
);

const SummaryList = styled.ul`
    padding-inline-start: 0;
`;

const SummaryItem = (() => {
    const Root = styled.li<{ active?: boolean }>(
        p => css`
            ${p.active &&
                css`
                    color: ${p.theme.palette.blue};
                `}

            display: grid;
            grid-template-areas:
                "primary arrow"
                "secondary arrow";
            grid-template-columns: 1fr auto;
            grid-template-rows: auto auto;
            font-weight: bold;
            transition: all 0.5s ease;
            padding: 1rem;

            @media ${desktop.andUp().toString()} {
                margin-left: -1rem;
            }
            @media ${desktop.smallerThanThis().toString()} {
                margin-left: 2rem;
            }

            border-radius: 1rem;
            cursor: pointer;

            :hover {
                background-color: #0001;
            }

            position: relative;

            ::before {
                content: "•";
                position: absolute;
                left: -1.5rem;
                top: 35%;
            }
        `,
    );

    const Primary = styled.span`
        grid-area: primary;
        font-size: 1.25rem;
        line-height: 2rem;
    `;

    const Secondary = styled.span(
        p => css`
            grid-area: secondary;
            font-size: 0.875rem;
            line-height: 2rem;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: ${themeColor("fontSemiDark")};
        `,
    );

    const Arrow = styled.img`
        grid-area: arrow;
        align-self: center;
        justify-self: center;
        width: 2rem;
        height: 2rem;

        @media ${desktop.smallerThanThis().toString()} {
            transform: rotate(90deg);
        }
    `;

    interface SummaryItemProps {
        active?: boolean;
        primary?: ReactNode;
        secondary?: ReactNode;
        onClick?(): void;
    }
    function SummaryItem(props: SummaryItemProps) {
        return (
            <Root active={props.active} onClick={props.onClick}>
                <Primary>{props.primary}</Primary>
                <Secondary>{props.secondary}</Secondary>
                <Arrow src={arrowRight} />
            </Root>
        );
    }
    SummaryItem.defaultProps = {
        active: false,
        primary: null,
        secondary: null,
    };

    return SummaryItem;
})();

const CardHeader = styled.div`
    @media ${desktop.andUp().toString()} {
        white-space: nowrap;
        & > * {
            vertical-align: middle;
        }
    }
    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;

const CardTitle = styled(Title)(
    p => css`
        margin-block-start: 0;
        margin-block-end: 0;
        @media ${desktop.andUp().toString()} {
            display: inline-block;
        }
        text-transform: uppercase;
        font-size: 1.25rem;
        line-height: 2rem;
        letter-spacing: 2px;
    `,
);

const CardSubtitle = styled.h4(
    p => css`
        margin-block-start: 0;
        margin-block-end: 0;
        vertical-align: middle;
        font-size: 1rem;
        line-height: 2;
        color: ${themeColor("fontSemiDark")};

        @media ${desktop.andUp().toString()} {
            display: inline-block;

            ::before {
                content: "·";
                ${margin.x("1rem")};
            }
        }
    `,
);

const ChapterContent = styled(Markdown)`
    color: ${themeColor("fontGrey")};
    ${zeplinLerp("font-size", 16, 24)};
    line-height: 2rem;

    ol {
        list-style: none;
        padding-inline-start: 0;
        ${childSpacing({ vertical: "4rem" })};
        counter-reset: chapter-step;
    }

    ol > li {
        /* display: flex; */
        /* align-items: center; */
        position: relative;

        ${zeplinLerp("--size", 3 * 16, 5 * 16)};
        ${zeplinLerp("--before-margin", 32, 48)};
        padding-left: calc(var(--size) + var(--before-margin));

        ::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            counter-increment: chapter-step;
            content: counter(chapter-step);

            border-radius: 50%;
            background-color: ${themeColor("disabled")};

            width: var(--size);
            height: var(--size);

            display: flex;
            align-items: center;
            justify-content: center;
            ${zeplinLerp("font-size", 24, 40)};
            line-height: 1.25;
            ${zeplinLerp("margin-right", 32, 48)};
            z-index: 1;
        }

        @media ${desktop.andUp().toString()} {
            /* vertical line */
            &:not(:first-of-type)::after {
                content: "";
                position: absolute;
                background-color: ${themeColor("disabled")};
                bottom: 50%;
                left: calc(var(--size) / 2);
                width: 2px;
                height: 6rem;
            }
        }
    }
`;

const CardStepItem = (() => {
    const Root = styled.li`
        display: flex;
        align-items: center;

        ${zeplinLerp("--spacing", (2 * 16) / 2, 56 / 2)};
        ${childSpacing({ horizontal: "var(--spacing)" })};

        > :first-child {
            flex: 0 0 auto;
        }
        > :last-child {
            flex: 1;
        }

        :first-child > :first-child::before {
            display: none;
        }
    `;

    const Bullet = styled.div(
        p => css`
            border-radius: 50%;
            background-color: ${p.theme.palette.disabled};

            ${zeplinLerp("--size", 3 * 16, 5 * 16)};
            width: var(--size);
            height: var(--size);

            display: flex;
            align-items: center;
            justify-content: center;
            ${zeplinLerp("font-size", 24, 40)};
            line-height: 1.25;

            position: relative;

            @media ${desktop.andUp().toString()} {
                /* vertical line */
                ::before {
                    content: "";
                    position: absolute;
                    background-color: inherit;
                    bottom: 100%;
                    left: 50%;
                    width: 2px;
                    height: 5rem;
                }
            }
        `,
    );

    const Content = styled(Markdown)(
        p => css`
            p {
                margin-block-start: 0;
                margin-block-end: 0;
                ${zeplinLerp("font-size", 16, 24)};
                line-height: calc(32 / 24);
                font-weight: bold;
            }
            strong {
                ${zeplinLerp("font-size", 16, 18)};
                line-height: 2rem;
                text-transform: uppercase;
                color: ${themeColor("fontSemiDark")};
            }
        `,
    );

    interface CardStepItemProps {
        index: number;
        children: string;
    }
    function CardStepItem(props: CardStepItemProps) {
        return (
            <Root>
                <Bullet>{props.index}</Bullet>
                <Content>{props.children}</Content>
            </Root>
        );
    }
    return CardStepItem;
})();

const MobileSlider = styled(Slider)``;

const MobileSliderDotsContainer = styled.div`
    ${margin.y("2rem")};
    display: flex;
    justify-content: center;
    ${childSpacing({ horizontal: "0.5rem" })};
    list-style: none;
`;

const MobileSliderDot = styled.div`
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${themeColor("fontGrey")};
    transition: all ${themeTransition("fast")};
    li.slick-active > & {
        background-color: ${themeColor("blue")};
    }
`;

const MobileSlideWrapper = styled.div`
    ${padding.x("2rem")};
`;

function Section12() {
    const content = useContext(ContentContext).home.section12;
    const [currentChapterIdx, setCurrentChapterIdx] = useState(0);
    const currentChapter = content.chapters[currentChapterIdx];
    const contentRef = useRef<HTMLDivElement>(null);
    const mobileSliderRef = useRef<Slider>(null);

    const windowSize = useWindowSize({ innerWidth: 1280, innerHeight: 800 });
    const subsectionHeight = windowSize.innerHeight / 2;

    const isMobile = useMediaSSR(desktop.smallerThanThis().toString(), SSRMQValues);

    useEffect(() => {
        if (isMobile) {
            return;
        }
        const callback = () => {
            if (!contentRef.current || !contentRef.current.parentElement) {
                return;
            }
            const offsetFromParent = contentRef.current.offsetTop - contentRef.current.parentElement.offsetTop;
            const cardIdx = Math.min(Math.floor(offsetFromParent / subsectionHeight), content.chapters.length - 1);
            setCurrentChapterIdx(cardIdx);
        };
        addEventListener("scroll", callback);
        return () => {
            removeEventListener("scroll", callback);
        };
    }, [content.chapters.length, subsectionHeight, isMobile]);

    const scrollToCard = useCallback(
        (cardIdx: number) => {
            if (!contentRef.current || !contentRef.current.parentElement) {
                return;
            }
            window.scrollTo({
                top: contentRef.current.parentElement.offsetTop + 30 + cardIdx * subsectionHeight,
                behavior: "smooth",
            });
        },
        [subsectionHeight],
    );

    const sliderGoToCard = useCallback((cardIdx: number) => {
        if (!mobileSliderRef.current) {
            return;
        }
        mobileSliderRef.current.slickGoTo(cardIdx);
    }, []);

    const goToCard = useCallback(
        (cardIdx: number) => {
            if (isMobile) {
                sliderGoToCard(cardIdx);
            } else {
                scrollToCard(cardIdx);
            }
        },
        [isMobile, scrollToCard, sliderGoToCard],
    );

    return (
        <Root id="get-started" subsectionHeight={`${subsectionHeight}px`} subsectionCount={content.chapters.length}>
            <Content ref={contentRef}>
                <Panes>
                    <MainPane>
                        <Header>{content.header}</Header>
                        <Text>{content.text}</Text>
                        <SummaryList>
                            {content.chapters.map((card, idx) => (
                                <SummaryItem
                                    key={card.key}
                                    active={card.key === currentChapter.key}
                                    onClick={() => {
                                        goToCard(idx);
                                    }}
                                    primary={card.title}
                                />
                            ))}
                        </SummaryList>
                    </MainPane>
                    {!isMobile ? (
                        <CardPane>
                            <ChapterContent>{currentChapter.content}</ChapterContent>
                        </CardPane>
                    ) : (
                        <MobileSlider
                            ref={mobileSliderRef}
                            centerMode
                            centerPadding="0"
                            arrows={false}
                            touchThreshold={100}
                            infinite={false}
                            beforeChange={(_, next) => setCurrentChapterIdx(next)}
                            dots
                            appendDots={dots => <MobileSliderDotsContainer>{dots}</MobileSliderDotsContainer>}
                            customPaging={idx => <MobileSliderDot />}
                        >
                            {content.chapters.map(chapter => (
                                <MobileSlideWrapper key={chapter.key}>
                                    <CardPane>
                                        <ChapterContent>{chapter.content}</ChapterContent>
                                    </CardPane>
                                </MobileSlideWrapper>
                            ))}
                        </MobileSlider>
                    )}
                </Panes>
            </Content>
        </Root>
    );
}
export default Section12;
