import React, { createContext, ReactNode, useState } from "react";

export const EmployerModeContext = createContext<boolean>(false);
export const SetEmployerModeContext = createContext<(employerMode: boolean) => void>(() => {});

export function EmployerModeProvider({ children }: { children: ReactNode }) {
    /**
     * Mode for mobile
     */
    const [employerMode, setEmployerMode] = useState(false);

    return (
        <SetEmployerModeContext.Provider value={setEmployerMode}>
            <EmployerModeContext.Provider value={employerMode}>{children}</EmployerModeContext.Provider>
        </SetEmployerModeContext.Provider>
    );
}
