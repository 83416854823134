import Markdown from "components/markdown";
import { graphql, useStaticQuery, withPrefix } from "gatsby";
import GatsbyImage from "gatsby-image";
import dropProps from "hoc/drop-props";
import { ContentContext } from "hooks/use-content";
import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { desktop } from "styles/media-queries";
import { childSpacing, contentCss, margin, padding, rootCss } from "styles/utils";
import zeplinLerp from "styles/zeplin-lerp";
import { Badge } from "components/badge";

const Root = styled.section`
    overflow: visible;

    @media ${desktop.andUp().toString()} {
        border-bottom: 1px solid lightgrey;
    }
    @media ${desktop.smallerThanThis().toString()} {
        background-color: white;
    }
`;

const BoxFragment = styled.div`
    ${rootCss}
`;

const BoxFragmentInner = styled.div`
    ${contentCss}
`;

const ContentBox = styled.div(
    p => css`
        ${padding.y("80px")};

        @media ${desktop.andUp().toString()} {
            --inset: 110px;
            margin-left: calc(var(--inset) * -1);
            padding-left: calc(var(--inset));
            margin-right: -20vw;
            padding-right: calc(20vw + var(--inset));
            border-radius: 2rem;
            background-image: linear-gradient(to bottom, white, ${p.theme.palette.backgroundLight});
        }

        @media ${desktop.smallerThanThis().toString()} {
            padding-bottom: 0;
            margin-bottom: -10rem;
        }
    `,
);

const Heading = styled.hgroup`
    @media ${desktop.andUp().toString()} {
    }
`;

const Title = styled.h3(
    p => css`
        text-transform: uppercase;
        color: ${p.theme.palette.green};
        font-size: 1rem;
        line-height: 2;
        letter-spacing: 1.6px;
        font-weight: normal;
        margin-block-start: 0;
        margin-block-end: 0;

        @media ${desktop.smallerThanThis().toString()} {
            text-align: center;
        }
    `,
);

const Tagline = styled(Markdown)`
    ${zeplinLerp("font-size", 24, 40)};
    ${zeplinLerp("line-height", 38, 72)};
    margin-block-start: 0.5rem;
    p {
        margin: 0;
    }
    strong {
        font-weight: bold;
    }

    @media ${desktop.smallerThanThis().toString()} {
        text-align: center;
    }
`;

const Body = styled.div`
    margin-top: 95px;
    display: flex;
    @media ${desktop.smallerThanThis().toString()} {
        flex-direction: column-reverse;
    }
`;

const ImageWrapper = styled.div`
    flex: 1;
    @media ${desktop.andUp().toString()} {
        min-height: 440px;
    }
    position: relative;
`;

const ImageWrapperInner = styled.div`
    @media ${desktop.andUp().toString()} {
        position: absolute;
        top: 0;
        right: 0;
        width: 130%;
    }
    height: auto;
    @media ${desktop.smallerThanThis().toString()} {
        width: 100%;
        max-width: 400px;
        ${margin.centerX};
    }
`;

const List = styled.ul`
    ${childSpacing({ vertical: "3rem" })};
    align-self: center;
    flex: 1;

    @media ${desktop.smallerThanThis().toString()} {
        padding-inline-start: 0;
    }
`;
const ListItem = styled(dropProps("icon")("li"))<{ icon: string }>(
    p => css`
        list-style: none;
        background-image: url(${p.icon});
        background-size: 32px;
        min-height: 32px;
        background-position: left center;
        background-repeat: no-repeat;
        ${zeplinLerp("padding-left", 32 + 24, 32 + 48)};
        ${zeplinLerp("font-size", 16, 21)};
        ${zeplinLerp("line-height", 24, 32)};
    `,
);

const Badges = styled.div`
    display: flex;
    justify-content: center;
`;

const StyledBadge = styled(Badge)`
    height: 30px;

    &:last-of-type {
        margin-left: 30px;
    }
`;

function Section07() {
    const content = useContext(ContentContext).home.section07;
    const commonContent = useContext(ContentContext).common;
    const data = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "home/section07/img.png" }) {
                childImageSharp {
                    fluid(maxWidth: 791) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <Root id="about-app">
            <BoxFragment>
                <BoxFragmentInner>
                    <ContentBox>
                        <Heading>
                            <Title>{content.title}</Title>
                            <Tagline>{content.header}</Tagline>
                        </Heading>
                        <Body>
                            <ImageWrapper>
                                <ImageWrapperInner>
                                    <GatsbyImage {...data.image.childImageSharp} />
                                    {/* <AspectRatio aspectRatio={791 / 1032}>
                                        <img src={image} />
                                    </AspectRatio> */}
                                </ImageWrapperInner>
                            </ImageWrapper>
                            <List>
                                {content.list.map(item => (
                                    <ListItem key={item.key} icon={withPrefix(item.icon)}>
                                        {item.text}
                                    </ListItem>
                                ))}
                                <Badges>
                                    <StyledBadge
                                        link={commonContent.appStore.link}
                                        image={commonContent.appStore.logo}
                                    />
                                    <StyledBadge
                                        link={commonContent.googlePlay.link}
                                        image={commonContent.googlePlay.logo}
                                    />
                                </Badges>
                            </List>
                        </Body>
                    </ContentBox>
                </BoxFragmentInner>
            </BoxFragment>
        </Root>
    );
}
export default Section07;
